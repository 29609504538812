<template>
  <v-footer color="transparent">
    <v-container class="py-5">
      <v-row>
        <v-col
          v-for="(item, i) in footer"
          :key="i"
          cols="6"
          sm="3"
          lg="3"
        >
          <div class="mb-3 body-4 font-weight-bold text-uppercase">{{ item.title }}</div>

          <div v-for="(link, j) in item.links" :key="j" class="my-1 body-2">
            <router-link
              v-if="link.to"
              class="text-decoration-none primary--text text--darken-1"
              :to="link.to"
            >{{ link.label }}</router-link>
            <a
              v-else
              class="text-decoration-none primary--text text--darken-1"
              :href="link.href"
              :target="link.target || 'blank'"
            >{{ link.label }}</a>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex flex-column flex-sm-row align-center justify-sm-space-between font-weight-bold text-uppercase caption my-4">
        <div class="d-flex flex-column flex-sm-row align-center">
          <div>© 2020 Tanzen Medical</div>
          <router-link to="#" class="text-decoration-none ml-sm-2 primary--text">Privacy Policy</router-link>
          <router-link to="#" class="text-decoration-none ml-sm-2 primary--text">Terms of Service</router-link>
        </div>
        <div>
          <v-btn icon color="primary" href="https://www.facebook.com/tanzenmed"><v-icon>mdi-facebook</v-icon></v-btn>
          <v-btn icon color="primary" href="https://www.linkedin.com/company/tanzen-medical-inc"><v-icon>mdi-linkedin</v-icon></v-btn>
          <!-- <v-btn icon color="secondary lighten-1" href="https://www.facebook.com/tanzenmed"><v-icon>mdi-twitter</v-icon></v-btn> -->
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      footer: [{
        title: 'Mission',
        links: [{
          label: 'Intro',
          to: { name: 'home', hash: '#home-intro' }
        }, {
          label: 'Our Mission',
          to: { name: 'home', hash: '#our-mission' }
        }, {
          label: 'Medical Need',
          to: { name: 'home', hash: '#medical-need' }
        }, {
          label: 'Solution',
          to: { name: 'home', hash: '#our-solution' }
        }]
      }, {
        title: 'Products',
        links: [{
          label: 'RestEaze',
          to: { name: 'products', hash: '#resteaze-intro' }
        }, {
          label: 'Features',
          to: { name: 'products', hash: '#features' }
        }, {
          label: 'Approach',
          to: { name: 'products', hash: '#approach' }
        }, {
          label: 'Metrics',
          to: { name: 'products', hash: '#metrics' }
        }, {
          label: 'Components',
          to: { name: 'products', hash: '#components' }
        }]
      }, {
        title: 'Company',
        links: [{
          label: 'Statement',
          to: { name: 'about', hash: '#statement' }
        }, {
          label: 'Intellectual Property',
          to: { name: 'about', hash: '#intellectual-property' }
        }, {
          label: 'Company Timeline',
          to: { name: 'about', hash: '#timeline' }
        }, {
          label: 'Leadership Team',
          to: { name: 'about', hash: '#leadership' }
        }, {
          label: 'Advisory Board',
          to: { name: 'about', hash: '#advisory-board' }
        }]
      }, {
        title: 'Contact us',
        links: [{
          label: 'Contacts',
          to: { name: 'contact', hash: '#contacts' }
        }, {
          label: 'FAQ',
          to: { name: 'contact', hash: '#faq' }
        }]
      }]
    }
  }
}
</script>
